// import electroForklift from '../assets/forklifts/JAC/TitlePictures/electricForklift.png';
import petForklift from '../assets/forklifts/JAC/TitlePictures/petrolForklift.png';
import disForklift from '../assets/forklifts/JAC/TitlePictures/dieselForklift.png';
// import richtrakPhotos from '../assets/forklifts/JAC/TitlePictures/richtrakForklift.png';

// import CPD13 from '../assets/forklifts/JAC/Electric/cpd13.png';
// import CPD15 from '../assets/forklifts/JAC/Electric/cpd15.png';
// import CPD16 from '../assets/forklifts/JAC/Electric/cpd16.png';
// import CPD18 from '../assets/forklifts/JAC/Electric/cpd18.png';
// import CPD20 from '../assets/forklifts/JAC/Electric/cpd20.png';
// import CPD25 from '../assets/forklifts/JAC/Electric/cpd25.png';
// import CPD30 from '../assets/forklifts/JAC/Electric/cpd30.png';
// import CPD35 from '../assets/forklifts/JAC/Electric/cpd35.png';
// import CPD40 from '../assets/forklifts/JAC/Electric/cpd40.png';
// import CPD45 from '../assets/forklifts/JAC/Electric/cpd45.png';
// import CPD50 from '../assets/forklifts/JAC/Electric/cpd50.png';
import CPD60 from '../assets/forklifts/JAC/Electric/cpd60.png';

// import CPQD10 from '../assets/forklifts/JAC/Petrol/CPQD10.png';
import CPQD15 from '../assets/forklifts/JAC/Petrol/CPQD15.png';
import CPQD18 from '../assets/forklifts/JAC/Petrol/CPQD18.png';
import CPQD20 from '../assets/forklifts/JAC/Petrol/CPQD20.png';
import CPQD25 from '../assets/forklifts/JAC/Petrol/CPQD25.png';
import CPQD30 from '../assets/forklifts/JAC/Petrol/CPQD30.png';
import CPQD35 from '../assets/forklifts/JAC/Petrol/CPQD35.png';
// import CPQD50 from '../assets/forklifts/JAC/Petrol/CPQD50.png';

// import CPCD10 from '../assets/forklifts/JAC/Diesel/cpcd10.png';
import CPCD15 from '../assets/forklifts/JAC/Diesel/cpcd15.png';
import CPCD18 from '../assets/forklifts/JAC/Diesel/cpcd18.png';
import CPCD20 from '../assets/forklifts/JAC/Diesel/cpcd20.png';
import CPCD25 from '../assets/forklifts/JAC/Diesel/cpcd25.png';
import CPCD30 from '../assets/forklifts/JAC/Diesel/cpcd30.png';
import CPCD35 from '../assets/forklifts/JAC/Diesel/cpcd35.png';
// import CPCD40 from '../assets/forklifts/JAC/Diesel/cpcd40.png';
// import CPCD45 from '../assets/forklifts/JAC/Diesel/cpcd45.png';
import CPCD50 from '../assets/forklifts/JAC/Diesel/cpcd50.png';
// import CPCD50m from '../assets/forklifts/JAC/Diesel/cpcd50m.png';
// import CPCD60 from '../assets/forklifts/JAC/Diesel/cpcd60.png';
import CPCD70 from '../assets/forklifts/JAC/Diesel/cpcd70.png';
// import CPCD80 from '../assets/forklifts/JAC/Diesel/cpcd80.png';
import CPCD100 from '../assets/forklifts/JAC/Diesel/cpcd100.png';
// import CPCD120 from '../assets/forklifts/JAC/Diesel/cpcd120.png';
// import CPCD135 from '../assets/forklifts/JAC/Diesel/cpcd135.png';
// import CPCD160 from '../assets/forklifts/JAC/Diesel/cpcd160.png';
// import CPCD180 from '../assets/forklifts/JAC/Diesel/cpcd180.png';

import electroForkliftGoodsense from '../assets/forklifts/Goodsense/electroForkliftGoodsense.png';
import disForkliftGoodsense from '../assets/forklifts/Goodsense/disForkliftGoodsense.png';
import FB15 from '../assets/forklifts/Goodsense/FB15.png';
import FB18Li from '../assets/forklifts/Goodsense/FB18Li.png';
// import FB18S from '../assets/forklifts/Goodsense/FB18S.png';
// import FB18 from '../assets/forklifts/Goodsense/FB18.png';
// import FB20Li from '../assets/forklifts/Goodsense/FB20Li.png';
// import FB20S from '../assets/forklifts/Goodsense/FB20S.png';
// import FB20 from '../assets/forklifts/Goodsense/FB20.png';
// import FB25 from '../assets/forklifts/Goodsense/FB25.png';
// import FB30H from '../assets/forklifts/Goodsense/FB30H.png';
import FB30 from '../assets/forklifts/Goodsense/FB30.png';
// import FB35 from '../assets/forklifts/Goodsense/FB35.png';
// import FB50 from '../assets/forklifts/Goodsense/FB50.png';

import FD15 from '../assets/forklifts/Goodsense/FD15.png';
import FD18 from '../assets/forklifts/Goodsense/FD18.png';
import FD20 from '../assets/forklifts/Goodsense/FD20.png';
import FD25 from '../assets/forklifts/Goodsense/FD25.png';
// import FD25S from '../assets/forklifts/Goodsense/FD25.png';
import FD30 from '../assets/forklifts/Goodsense/FD30.png';
// import FD30S from '../assets/forklifts/Goodsense/FD30S.png';
import FD35 from '../assets/forklifts/Goodsense/FD35.png';
// import FD35S from '../assets/forklifts/Goodsense/FD35S.png';
import FD50 from '../assets/forklifts/Goodsense/FD50.png';
import FD70 from '../assets/forklifts/Goodsense/FD70.png';
// import FD100 from '../assets/forklifts/Goodsense/FD100.png';

import shannForklifts from '../assets/forklifts/SHANN/shannForklifts.png';
import SHANN15 from '../assets/forklifts/SHANN/SHANN15.png';
import SHANN20 from '../assets/forklifts/SHANN/SHANN20.png';
import SHANN25 from '../assets/forklifts/SHANN/SHANN25.png';
import SHANN30 from '../assets/forklifts/SHANN/SHANN30.png';
import SHANN35 from '../assets/forklifts/SHANN/SHANN35.png';
import SHANN50 from '../assets/forklifts/SHANN/SHANN50.png';
// import SHANN70 from '../assets/forklifts/SHANN/SHANN70.png';
// import SHANN80 from '../assets/forklifts/SHANN/SHANN80.png';
// import SHANN100 from '../assets/forklifts/SHANN/SHANN100.png';

import electric_stab from '../assets/forklifts/SmallEquipment/electric_stab.png';
import trolley from '../assets/forklifts/SmallEquipment/trolley.png';
import electric_trolley from '../assets/forklifts/SmallEquipment/electric_trolley.png';

export const result = [
  {
    url: 'goodsense_forklifts',
    title: 'Вилочные погрузчики Goodsense',
    img: disForkliftGoodsense,
    elems: [
      {
        url: 'dieselForkliftGoodsense',
        title: 'Дизельные погрузчики',
        img: disForkliftGoodsense,
        pages: [
          {
            title: 'Дизельный погрузчик GOODSENSE FD15',
            img: FD15,
            description: {
              model: 'FD15',
              loadCapacity: '1.5 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3м/4.8м',
              wheelType: 'Пневматические',
            },
          },
          {
            title: 'Дизельный погрузчик GOODSENSE FD18',
            img: FD18,
            description: {
              model: 'FD18',
              loadCapacity: '1.8 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3м/4.8м',
              wheelType: 'Пневматические',
            },
          },
          {
            title: 'Дизельный погрузчик GOODSENSE FD20',
            img: FD20,
            description: {
              model: 'FD20',
              loadCapacity: '2.0 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3м/4.8м',
              wheelType: 'Пневматические',
            },
          },
          {
            title: 'Дизельный погрузчик GOODSENSE FD25',
            img: FD25,
            description: {
              model: 'FD25',
              loadCapacity: '2.5 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3м/4.8м',
              wheelType: 'Пневматические',
            },
          },
          // {
          //   title: 'Дизельный погрузчик GOODSENSE FD25S',
          //   img: FD25S,
          //   description: {
          //     model: 'FD25S',
          //     loadCapacity: '2.5 тонны',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3.0м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          {
            title: 'Дизельный погрузчик GOODSENSE FD30',
            img: FD30,
            description: {
              model: 'FD30',
              loadCapacity: '3.0 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3м/4.8м',
              wheelType: 'Пневматические',
            },
          },
          // {
          //   title: 'Дизельный погрузчик GOODSENSE FD30S',
          //   img: FD30S,
          //   description: {
          //     model: 'FD30S',
          //     loadCapacity: '3.0 тонны',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3.0м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          {
            title: 'Дизельный погрузчик GOODSENSE FD35',
            img: FD35,
            description: {
              model: 'FD35',
              loadCapacity: '3.5 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3м/4.8м',
              wheelType: 'Пневматические',
            },
          },
          // {
          //   title: 'Дизельный погрузчик GOODSENSE FD35S',
          //   img: FD35S,
          //   description: {
          //     model: 'FD35S',
          //     loadCapacity: '3.5 тонны',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3.0м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          {
            title: 'Дизельный погрузчик GOODSENSE FD50',
            img: FD50,
            description: {
              model: 'FD50',
              loadCapacity: '5.0 тонн',
              engineType: 'Дизельный',
              liftingHeight: '3м/4.5м',
              wheelType: 'Пневматические',
            },
          },
          {
            title: 'Дизельный погрузчик GOODSENSE FD70',
            img: FD70,
            description: {
              model: 'FD70',
              loadCapacity: '7.0 тонн',
              engineType: 'Дизельный',
              liftingHeight: '3м/4.5м',
              wheelType: 'Пневматические',
            },
          },
          // {
          //   title: 'Дизельный погрузчик GOODSENSE FD100',
          //   img: FD100,
          //   description: {
          //     model: 'FD100',
          //     loadCapacity: '10.0 тонн',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3м',
          //     wheelType: 'Пневматические',
          //   },
          // },
        ],
      },
      {
        url: 'electricForkliftGoodsense',
        title: 'Электропогрузчики',
        img: electroForkliftGoodsense,
        pages: [
          {
            title: 'Электрический погрузчик GOODSENSE FB15Li',
            img: FB15,
            description: {
              model: 'FB15Li',
              loadCapacity: '1.5 тонна',
              batteryCapacity: '400 А/ч',
              engineType: 'Электрический',
              liftingHeight: '3м / 4.8м',
              wheelType: 'Пневматические',
            },
          },
          {
            title: 'Электрический погрузчик GOODSENSE FB18Li',
            img: FB18Li,
            description: {
              model: 'FB18Li',
              loadCapacity: '1.8 тонн',
              batteryCapacity: '150 А/ч',
              engineType: 'Электрический',
              liftingHeight: '3м',
              wheelType: 'Пневматические',
            },
          },
          // {
          //   title: 'Электрический погрузчик GOODSENSE FB18S',
          //   img: FB18S,
          //   description: {
          //     model: 'FB18S',
          //     loadCapacity: '1.8 тонн',
          //     batteryCapacity: '490 А/ч',
          //     engineType: 'Электрический',
          //     liftingHeight: '4.8м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          // {
          //   title: 'Электрический погрузчик GOODSENSE FB18',
          //   img: FB18,
          //   description: {
          //     model: 'FB18',
          //     loadCapacity: '1.8 тонн',
          //     batteryCapacity: '490 А/ч',
          //     engineType: 'Электрический',
          //     liftingHeight: '3м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          // {
          //   title: 'Электрический погрузчик GOODSENSE FB20Li',
          //   img: FB20Li,
          //   description: {
          //     model: 'FB20Li',
          //     loadCapacity: '2.0 тонны',
          //     batteryCapacity: '150 А/ч',
          //     engineType: 'Электрический',
          //     liftingHeight: '3м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          // {
          //   title: 'Электрический погрузчик GOODSENSE FB20S',
          //   img: FB20S,
          //   description: {
          //     model: 'FB20S',
          //     loadCapacity: '2.0 тонны',
          //     batteryCapacity: '560 А/ч',
          //     engineType: 'Электрический',
          //     liftingHeight: '3.0м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          // {
          //   title: 'Электрический погрузчик GOODSENSE FB20',
          //   img: FB20,
          //   description: {
          //     model: 'FB20',
          //     loadCapacity: '2.0 тонны',
          //     batteryCapacity: '560 А/ч',
          //     engineType: 'Электрический',
          //     liftingHeight: '4.8м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          // {
          //   title: 'Электрический погрузчик GOODSENSE FB25',
          //   img: FB25,
          //   description: {
          //     model: 'FB25',
          //     loadCapacity: '2.5 тонны',
          //     batteryCapacity: '630 А/ч',
          //     engineType: 'Электрический',
          //     liftingHeight: '3.0 / 4.8м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          // {
          //   title: 'Электрический погрузчик GOODSENSE FB30H',
          //   img: FB30H,
          //   description: {
          //     model: 'FB30H',
          //     loadCapacity: '3.0 тонны',
          //     batteryCapacity: '500 А/ч',
          //     engineType: 'Электрический',
          //     liftingHeight: '3.0м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          {
            title: 'Электрический погрузчик GOODSENSE FB30Li',
            img: FB30,
            description: {
              model: 'FB30Li',
              loadCapacity: '3.0 тонны',
              batteryCapacity: '500 А/ч',
              engineType: 'Электрический',
              liftingHeight: '4.5м / 3.0м',
              wheelType: 'Пневматические',
            },
          },
          // {
          //   title: 'Электрический погрузчик GOODSENSE FB35',
          //   img: FB35,
          //   description: {
          //     model: 'FB35',
          //     loadCapacity: '3.5 тонн',
          //     batteryCapacity: '550 А/ч',
          //     engineType: 'Электрический',
          //     liftingHeight: '3.0м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          // {
          //   title: 'Электрический погрузчик GOODSENSE FB50',
          //   img: FB50,
          //   description: {
          //     model: 'FB50',
          //     loadCapacity: '5.0 тонн',
          //     batteryCapacity: '700 А/ч',
          //     engineType: 'Электрический',
          //     liftingHeight: '3.0м',
          //     wheelType: 'Пневматические',
          //   },
          // },
        ],
      },
    ],
  },
  {
    url: 'jac_forklifts',
    title: 'Вилочные погрузчики JAC',
    img: disForklift,
    elems: [
      {
        url: 'dieselForkliftJAC',
        title: 'Дизельные погрузчики',
        img: disForklift,
        pages: [
          // {
          //   title: 'Дизельный вилочный погрузчик JAC CPCD 10',
          //   img: CPCD10,
          //   description: {
          //     model: 'CPCD 10',
          //     loadCapacity: '1 тонна',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
          {
            title: 'Дизельный вилочный погрузчик JAC CPCD 15',
            img: CPCD15,
            description: {
              model: 'CPCD 15',
              loadCapacity: '1,5 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          {
            title: 'Дизельный вилочный погрузчик JAC CPCD 18',
            img: CPCD18,
            description: {
              model: 'CPCD 18',
              loadCapacity: '1,8 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          {
            title: 'Дизельный вилочный погрузчик JAC CPCD 20',
            img: CPCD20,
            description: {
              model: 'CPCD 20',
              loadCapacity: '2 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          {
            title: 'Дизельный вилочный погрузчик JAC CPCD 25',
            img: CPCD25,
            description: {
              model: 'CPCD 25',
              loadCapacity: '2,5 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          {
            title: 'Дизельный вилочный погрузчик JAC CPCD 30',
            img: CPCD30,
            description: {
              model: 'CPCD 30',
              loadCapacity: '3 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          {
            title: 'Дизельный вилочный погрузчик JAC CPCD 35',
            img: CPCD35,
            description: {
              model: 'CPCD 35',
              loadCapacity: '3,5 тонны',
              engineType: 'Дизельный',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          // {
          //   title: 'Дизельный вилочный погрузчик JAC CPCD 40',
          //   img: CPCD40,
          //   description: {
          //     model: 'CPCD 40',
          //     loadCapacity: '4 тонны',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
          // {
          //   title: 'Дизельный вилочный погрузчик JAC CPCD 45',
          //   img: CPCD45,
          //   description: {
          //     model: 'CPCD 45',
          //     loadCapacity: '4,5 тонны',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
          {
            title: 'Дизельный вилочный погрузчик JAC CPCD 50',
            img: CPCD50,
            description: {
              model: 'CPCD 50',
              loadCapacity: '5 тонн',
              engineType: 'Дизельный',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          // {
          //   title: 'Дизельный вилочный погрузчик JAC CPCD 50 mini',
          //   img: CPCD50m,
          //   description: {
          //     model: 'CPCD50 mini',
          //     loadCapacity: '5 тонн',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
          // {
          //   title: 'Дизельный вилочный погрузчик JAC CPCD 60',
          //   img: CPCD60,
          //   description: {
          //     model: 'CPCD 60',
          //     loadCapacity: '6 тонн',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
          {
            title: 'Дизельный вилочный погрузчик JAC CPCD 70',
            img: CPCD70,
            description: {
              model: 'CPCD 70',
              loadCapacity: '7 тонн',
              engineType: 'Дизельный',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          // {
          //   title: 'Дизельный вилочный погрузчик JAC CPCD 80',
          //   img: CPCD80,
          //   description: {
          //     model: 'CPCD 80',
          //     loadCapacity: '8 тонн',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
          {
            title: 'Дизельный вилочный погрузчик JAC CPCD 100',
            img: CPCD100,
            description: {
              model: 'CPCD 100',
              loadCapacity: '10 тонн',
              engineType: 'Дизельный',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          // {
          //   title: 'Дизельный вилочный погрузчик JAC CPCD 120',
          //   img: CPCD120,
          //   description: {
          //     model: 'CPCD 120',
          //     loadCapacity: '12 тонн',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
          // {
          //   title: 'Дизельный вилочный погрузчик JAC CPCD 135',
          //   img: CPCD135,
          //   description: {
          //     model: 'CPCD 135',
          //     loadCapacity: '13,5 тонн',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
          // {
          //   title: 'Дизельный вилочный погрузчик JAC CPCD 160',
          //   img: CPCD160,
          //   description: {
          //     model: 'CPCD 160',
          //     loadCapacity: '16 тонн',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
          // {
          //   title: 'Дизельный вилочный погрузчик JAC CPCD 180...420',
          //   img: CPCD180,
          //   description: {
          //     model: 'CPCD 180...420',
          //     loadCapacity: 'до 60 тонн',
          //     engineType: 'Дизельный',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
        ],
      },
      {
        url: 'petrolForkliftJAC',
        title: 'Бензиновые погрузчики',
        img: petForklift,
        pages: [
          // {
          //   title: 'Бензиновый вилочный погрузчик JAC CPQD 10',
          //   img: CPQD10,
          //   description: {
          //     model: 'CPQD 10',
          //     loadCapacity: '1 тонна',
          //     engineType: 'Газ-бензин',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
          {
            title: 'Бензиновый вилочный погрузчик JAC CPQD 15',
            img: CPQD15,
            description: {
              model: 'CPQD 15',
              loadCapacity: '1,5 тонны',
              engineType: 'Газ/бензин',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          {
            title: 'Бензиновый вилочный погрузчик JAC CPQD 18',
            img: CPQD18,
            description: {
              model: 'CPQD 18',
              loadCapacity: '1,8 тонны',
              engineType: 'Газ/бензин',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          {
            title: 'Бензиновый вилочный погрузчик JAC CPQD 20',
            img: CPQD20,
            description: {
              model: 'CPQD 20',
              loadCapacity: '2 тонны',
              engineType: 'Газ/бензин',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          {
            title: 'Бензиновый вилочный погрузчик JAC CPQD 25',
            img: CPQD25,
            description: {
              model: 'CPQD 25',
              loadCapacity: '2,5 тонны',
              engineType: 'Газ/бензин',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          {
            title: 'Бензиновый вилочный погрузчик JAC CPQD 30',
            img: CPQD30,
            description: {
              model: 'CPQD 30',
              loadCapacity: '3 тонны',
              engineType: 'Газ/бензин',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          {
            title: 'Бензиновый вилочный погрузчик JAC CPQD 35',
            img: CPQD35,
            description: {
              model: 'CPQD 35',
              loadCapacity: '3,5 тонны',
              engineType: 'Газ/бензин',
              liftingHeight: '3-8 м',
              wheelType: 'пневма/суперэластик',
            },
          },
          // {
          //   title: 'Бензиновый вилочный погрузчик JAC CPQD 50 mini',
          //   img: CPQD50,
          //   description: {
          //     model: 'CPQD50 mini',
          //     loadCapacity: '5 тонн',
          //     engineType: 'Газ-бензин',
          //     liftingHeight: '3-8 м',
          //     wheelType: 'пневма/суперэластик',
          //   },
          // },
        ],
      },
      // {
      //   url: 'electricForkliftJAC',
      //   title: 'Электропогрузчики',
      //   img: electroForklift,
      //   pages: [
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 13',
      //       img: CPD13,
      //       description: {
      //         model: 'CPD 13',
      //         loadCapacity: '1.3 тонны',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-8 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 15',
      //       img: CPD15,
      //       description: {
      //         model: 'CPD 15',
      //         loadCapacity: '1.5 тонны',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-8 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 16',
      //       img: CPD16,
      //       description: {
      //         model: 'CPD 16',
      //         loadCapacity: '1.6 тонны',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-8 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 18',
      //       img: CPD18,
      //       description: {
      //         model: 'CPD 18',
      //         loadCapacity: '1.8 тонны',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-8 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 20',
      //       img: CPD20,
      //       description: {
      //         model: 'CPD 20',
      //         loadCapacity: '2 тонны',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-8 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 25',
      //       img: CPD25,
      //       description: {
      //         model: 'CPD 25',
      //         loadCapacity: '2,5 тонны',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-8 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 30 GT',
      //       img: CPD30,
      //       description: {
      //         model: 'CPD 30',
      //         loadCapacity: '3 тонны',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-8 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 35',
      //       img: CPD35,
      //       description: {
      //         model: 'CPD 35',
      //         loadCapacity: '3,5 тонны',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-8 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 40',
      //       img: CPD40,
      //       description: {
      //         model: 'CPD 40',
      //         loadCapacity: '4 тонны',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-6 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 45',
      //       img: CPD45,
      //       description: {
      //         model: 'CPD 45',
      //         loadCapacity: '4,5 тонны',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-6 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 50',
      //       img: CPD50,
      //       description: {
      //         model: 'CPD 50',
      //         loadCapacity: '5 тонн',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-8 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //     {
      //       title: 'Электрический вилочный погрузчик JAC CPD 60',
      //       img: CPD60,
      //       description: {
      //         model: 'CPD 60',
      //         loadCapacity: '6 тонн',
      //         engineType: 'Электрический',
      //         liftingHeight: '3-8 м',
      //         wheelType: 'пневма/суперэластик',
      //       },
      //     },
      //   ],
      // },
    ],
  },
  {
    url: 'shann_forklifts',
    title: 'Вилочные погрузчики SHANN',
    img: shannForklifts,
    elems: [
      {
        url: 'electricForkliftShann',
        title: 'Электропогрузчики',
        img: shannForklifts,
        pages: [
          {
            title: 'Электрический погрузчик SHANN 15',
            img: SHANN15,
            description: {
              model: 'SHANN 15',
              loadCapacity: '1.5 тонны',
              batteryCapacity: '500 А/ч (LiFePO12)',
              engineType: 'Электрический',
              liftingHeight: '3м',
              wheelType: 'Пневматические',
            },
          },
          {
            title: 'Электрический погрузчик SHANN 20',
            img: SHANN20,
            description: {
              model: 'SHANN 20',
              loadCapacity: '2.0 тонны',
              batteryCapacity: '100-125 А/ч (LiFePO4)',
              engineType: 'Электрический',
              liftingHeight: '3м',
              wheelType: 'Пневматические',
            },
          },
          {
            title: 'Электрический погрузчик SHANN 25',
            img: SHANN25,
            description: {
              model: 'SHANN 25',
              loadCapacity: '2.5 тонны',
              batteryCapacity: '100-125 А/ч (LiFePO5)',
              engineType: 'Электрический',
              liftingHeight: '3м',
              wheelType: 'Пневматические',
            },
          },
          {
            title: 'Электрический погрузчик SHANN 30',
            img: SHANN30,
            description: {
              model: 'SHANN 30',
              loadCapacity: '3.0 тонны',
              batteryCapacity: '230 А/ч (LiFePO6)',
              engineType: 'Электрический',
              liftingHeight: '3м',
              wheelType: 'Пневматические',
            },
          },
          {
            title: 'Электрический погрузчик SHANN 35',
            img: SHANN35,
            description: {
              model: 'SHANN 35',
              loadCapacity: '3.5 тонны',
              batteryCapacity: '230 А/ч (LiFePO6)',
              engineType: 'Электрический',
              liftingHeight: '3м',
              wheelType: 'Пневматические',
            },
          },
          {
            title: 'Электрический погрузчик SHANN 50',
            img: SHANN50,
            description: {
              model: 'SHANN 50',
              loadCapacity: '5.0 тонн',
              batteryCapacity: '130-280 А/ч (LiFePO8)',
              engineType: 'Электрический',
              liftingHeight: '3м',
              wheelType: 'Пневматические',
            },
          },
          // {
          //   title: 'Электрический погрузчик SHANN 70',
          //   img: SHANN70,
          //   description: {
          //     model: 'SHANN 70',
          //     loadCapacity: '7.0 тонн',
          //     batteryCapacity: '230 А/ч (LiFePO9)',
          //     engineType: 'Электрический',
          //     liftingHeight: '3м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          // {
          //   title: 'Электрический погрузчик SHANN 80',
          //   img: SHANN80,
          //   description: {
          //     model: 'SHANN 80',
          //     loadCapacity: '8.0 тонн',
          //     batteryCapacity: '230 А/ч (LiFePO10)',
          //     engineType: 'Электрический',
          //     liftingHeight: '3м',
          //     wheelType: 'Пневматические',
          //   },
          // },
          // {
          //   title: 'Электрический погрузчик SHANN 100',
          //   img: SHANN100,
          //   description: {
          //     model: 'SHANN 100',
          //     loadCapacity: '10.0 тонн',
          //     batteryCapacity: '230 А/ч (LiFePO11)',
          //     engineType: 'Электрический',
          //     liftingHeight: '3м',
          //     wheelType: 'Пневматические',
          //   },
          // },
        ],
      },
    ],
  },
  {
    url: 'small_equipment',
    title: 'Малая складская техника',
    img: electric_stab,
    elems: [
      {
        url: 'trolleys',
        title: 'Ручные тележки',
        img: trolley,
        pages: [
          {
            title: 'Ручные тележки',
            img: trolley,
            description: {
              loadCapacity: '1-3 т.',
              liftingHeight: '',
              longType: '1.15-2 м.',
              model: '',
              batteryCapacity: '',
              engineType: '',
              wheelType: '',
            },
          },
        ],
      },
      {
        url: 'electric_trolley',
        title: 'Самоходный перевозчик палетов',
        img: electric_trolley,
        pages: [
          {
            title: 'Самоходный перевозчик палетов',
            img: electric_trolley,
            description: {
              loadCapacity: '1-3 т.',
              liftingHeight: '',
              longType: '1.15-2 м.',
              model: '',
              batteryCapacity: '',
              engineType: '',
              wheelType: '',
            },
          },
        ],
      },
      {
        url: 'electric_stab',
        title: 'Штабелеры',
        img: electric_stab,
        pages: [
          {
            title: 'Штабелеры',
            img: electric_stab,
            description: {
              model: '',
              batteryCapacity: '',
              engineType: '',
              wheelType: '',
              loadCapacity: '1-3 т.',
              liftingHeight: '3-6 м.',
              longType: '1.15-2 м.',
            },
          },
        ],
      },
    ],
  },
];
